<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account-settings :userInfo="userInfo"></account-settings>
      </v-tab-item>

      <v-tab-item>
        <company-settings></company-settings>
      </v-tab-item>

    </v-tabs-items>
  </v-card>
</template>

<script>
import firebase from 'firebase'
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

import AccountSettings from './child_components/AccountSettings.vue'
import CompanySettings from './child_components/CompanySettings.vue'

export default {
  components: {
    AccountSettings,
    CompanySettings,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Account', icon: mdiAccountOutline },
      { title: 'Company Settings', icon: mdiBookmarkOutline },
    ]
    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
      },
    }
  },
  data() {
    return {
      associatedCompanyList: [],
      userInfo: {
        name: null,
        email: null,
      },
    }
  },
  mounted() {
    //get the user's info
    firebase
      .firestore()
      .collection('users')
      .where('name', '==', 'Chase Ernst')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.userInfo.name = doc.data().name
          this.userInfo.email = doc.data().email
        })
      })
  },
}
</script>
