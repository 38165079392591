<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="userInfo.name"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="userInfo.email"
              label="E-mail"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-data-table
              :headers="headers"
              :items="associatedCompanyList"
              :items-per-page="10"
              fixed-header
            >
            </v-data-table>
          </v-col>

          <!-- alert -->
          <v-col cols="12">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Your email is not confirmed. Please check your inbox.
                  </p>
                  <a
                    href="javascript:void(0)"
                    class="text-decoration-none warning--text"
                  >
                    <span class="text-sm">Resend Confirmation</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
            >
              Save changes
            </v-btn>
            <!-- <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="resetForm"
            >
              Cancel
            </v-btn> -->
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
// import { ref } from '@vue/composition-api'

export default {
  props: {
    associatedCompanyList: Array,
    userInfo: Object,
    // accountData: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  setup(props) {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    // const accountDataLocale = ref(JSON.parse(JSON.stringify(props.accountData)))

    // const resetForm = () => {
    //   accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
    // }

    return {
      status,
      // resetForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
